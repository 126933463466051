import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import bespokenLogo from '../../../images/bespoken.svg'
import './Footer.scss'

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col md="9">
            &copy; Catellani & Smith Srl - P.IVA e Reg. Impr. BG IT02656220163 - <a href="mailto:chandelier.configurator@catellanismith.com">chandelier.configurator@catellanismith.com</a>
          </Col>
          <Col md="3" className="logo-bespoken">
            <img src={bespokenLogo} alt="" style={{ width: 70 }} />
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
